<template>
  <v-container>
    <p class="title mb-0">Relatórios do ConselhoNET</p>
    <p class="subtitle-1">Base de dados I2BR</p>
    <v-divider class="mb-5"/>
    <v-sheet v-for="i in itemsSorted" :key="i.to" class="mb-3" outlined light rounded>
      <v-list-item :to="i.to">
        <v-list-item-content>
          <v-list-item-title>{{i.title}}</v-list-item-title>
          <v-list-item-subtitle>{{i.subtitle}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
  </v-container>
</template>

<script>
export default {
  name: 'Index',
  data: () => ({
    items: [
      {
        to: '/relatorios/conselhonet/fichascadastrais',
        title: 'Planilha de fichas cadastrais',
        subtitle: 'Extraia a ficha cadastral de vários corretores usando o número de CRECI ou CPF'
      },
      {
        to: '/relatorios/conselhonet/cidadescomprofissionais',
        title: 'Cidades com profissionais',
        subtitle: 'Consulte quais cidades possuem imobiliárias/corretores ativos',
      },
      {
        to: '/relatorios/conselhonet/semfotoass',
        title: 'Cadastros sem imagem',
        subtitle: 'Corretores "ativos" sem foto ou assinatura',
      },
      {
        to: '/relatorios/conselhonet/fotos',
        title: 'Download de fotos',
        subtitle: 'Baixe várias fotos de uma vez',
      },
    ]
  }),
  computed: {
    itemsSorted() {
      const i = this.items;
      return i.sort((a, b) => {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      });
    },
  }
}
</script>
