<template>
  <v-container>
    <!-- Tabela de corretores -->
    <v-data-table
      class="elevation-2"
      :items="itemsBeautifyed"
      :headers="headers"
      no-data-text="Nenhum corretor adicionado"
      :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
    >
      <template v-slot:top>
        <div class="d-flex justify-space-between align-center">
          <div>
            <v-card-title>Gerador de fichas cadastrais</v-card-title>
            <v-card-subtitle>Adicione corretores e gere a planilha</v-card-subtitle>
          </div>
          <v-btn color="primary" class="mr-2" @click="showModalAddCorretor = true">Adicionar</v-btn>
        </div>
      </template>
      <template v-slot:item.actions="{item}">
        <v-btn color="error" small rounded @click="removerCorretor(item.cpf)" outlined>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
      <template v-slot:footer>
        <div class="text-center text-sm-right pa-3" v-if="itemsBeautifyed.length > 1">
          <v-btn rounded color="error" small @click="items = []">Remover todos</v-btn>
        </div>
      </template>
    </v-data-table>
    <!-- Botão que gera a planilha -->
    <div class="text-center">
      <v-btn color="success" class="mt-3" @click="gerarPlanilha">Gerar planilha</v-btn>
    </div>
    <!-- Dialogo para adicionar corretores -->
    <v-dialog width="500" v-model="showModalAddCorretor">
      <v-card>
        <v-form @submit.prevent="adicionarCorretor" v-model="formValid">
          <v-card-title>Adicionar corretor</v-card-title>
          <v-card-text>
            <v-select
              label="Modo de seleção"
              :items="[
              {text: 'Adicionar um corretor por número de CRECI', value: 0},
              {text: 'Buscar um corretor por número de CPF', value: 1},
              {text: 'Adicionar multiplos corretores por intervalo de CRECI', value: 2},
            ]"
              v-model="addMode"
            />
            <v-text-field
              :label="addMode === 0 ? 'CRECI do corretor' : 'CPF do corretor'"
              v-if="addMode !== 2"
              v-model="creciCpf"
              type="number"
              :rules="addMode === 0 ? [rules.obrigatorio, rules.numerico] : [rules.obrigatorio, rules.numerico, rules.cpf]"
            ></v-text-field>
            <div v-else>
              <v-text-field
                label="CRECI inicial"
                v-model="creciInicial"
                type="number"
                :rules="[rules.obrigatorio, rules.numerico]"
              ></v-text-field>
              <v-text-field
                label="CRECI final"
                v-model="creciFinal"
                type="number"
                :rules="[rules.obrigatorio, rules.numerico, rulecreciInicialFinalPositivo]"
              ></v-text-field>
              <p class="caption">Não use intervalos acima de 100 corretores para não sobrecarregar o ConselhoNET</p>
            </div>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="primary" type="submit" :disabled="!formValid">Adicionar</v-btn>
            <v-btn color="secondary" type="button" @click="showModalAddCorretor = false">Cancelar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- Dialogo de carregamento... -->
    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text class="text-center">
          {{loadingDialogMessage}} <v-progress-linear indeterminate color="white" class="mb-0"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import I2brWebClient from '@/http/I2brWebClient';
import zipcelx from 'zipcelx';
import StringHelper from '@/helpers/StringHelper';
import moment from 'moment';
export default {
  name: 'FichasCadastrais',
  data: () => ({
    items: [],
    headers: [
      {value: 'nome', text: 'NOME'},
      {value: 'creci', text: 'CRECI'},
      {value: 'cpf', text: 'CPF'},
      {value: 'actions', text: 'AÇÕES', align: 'center', width: '10rem', sortable: false, filterable: false},
    ],
    showModalAddCorretor: false,
    addMode: 0,
    creciCpf: '',
    creciInicial: '',
    creciFinal: '',
    formValid: false,
    loadingDialog: false,
    loadingDialogMessage: '',
    rules: {
      obrigatorio: v => !!v || 'Este dado é obrigatório',
      numerico: v => (v && StringHelper.extractNumbers(v).length === v.trim().length) || 'Insira apenas números',
      cpf: v => (v && StringHelper.validCpf(v)) || 'Insira um CPF válido',
    },
  }),
  methods: {
    async adicionarCorretor() {
      if (!this.formValid) return;
      this.showModalAddCorretor = false;
      const webClientI2br = new I2brWebClient();
      let fichas;
      const crecis = [];
      switch (this.addMode) {
        case 0:
          if (this.items.find(i => i.dados_gerais.creci === parseInt(this.creciCpf))) {
            this.$snackbar({text: 'Este corretor ja está na lista', color: 'error'});
            return;
          }
          this.showLoadingDialog('Buscando corretores no ConselhoNET');
          fichas = await webClientI2br.getFichas([this.creciCpf], true);
          break;
        case 1:
          if (this.items.find(i => i.dados_gerais.cpf === this.creciCpf.trim())) {
            this.$snackbar({text: 'Este corretor ja está na lista', color: 'error'});
            return;
          }
          this.showLoadingDialog('Buscando corretores no ConselhoNET');
          fichas = await webClientI2br.getFichas([this.creciCpf], false);
          break;
        case 2:
          this.showLoadingDialog('Buscando corretores no ConselhoNET');
          for (let i = parseInt(this.creciInicial); i <= parseInt(this.creciFinal); i++) {
            if (!this.items.find(c => c.dados_gerais.creci === i)) crecis.push(i);
          }
          fichas = await webClientI2br.getFichas(crecis, true);
      }
      this.showLoadingDialog();
      if (!fichas) return;
      this.items = [...this.items, ...fichas.fichas];
    },
    removerCorretor(cpf) {
      const index = this.items.findIndex(i => i.dados_gerais.cpf === cpf);
      this.items.splice(index, 1);
    },
    gerarPlanilha() {
      if (this.items.length === 0) {
        this.$snackbar({text: 'Você precisa adicionar corretores na listagem'});
        return;
      }

      const header = [
        {type: 'string', value: 'CRECI'},
        {type: 'string', value: 'CPF'},
        {type: 'string', value: 'NOME'},
        {type: 'string', value: 'NPA'},
        {type: 'string', value: 'DATA DE INSCRICAO'},
        {type: 'string', value: 'SECUNDARIA'},
        {type: 'string', value: 'TRANSFERIDO'},
        {type: 'string', value: 'SEXO'},
        {type: 'string', value: 'CNAI'},
        {type: 'string', value: 'APTO CERTIDAO'},
        {type: 'string', value: 'VALIDADE CERTIDAO'},
        {type: 'string', value: 'RECEBEU CARTEIRA'},
        {type: 'string', value: 'SOLENIDADE DE ENTREGA'},
        {type: 'string', value: 'POSSUI FOTO'},
        {type: 'string', value: 'POSSUI ASSINATURA'},
        {type: 'string', value: 'RECADASTRADO'},

        {type: 'string', value: 'RG - NÚMERO'},
        {type: 'string', value: 'RG - EXPEDIDOR'},
        {type: 'string', value: 'NASCIMENTO'},
        {type: 'string', value: 'MÃE'},
        {type: 'string', value: 'PAI'},

        {type: 'string', value: 'END RES. - CEP'},
        {type: 'string', value: 'END RES. - ESTADO'},
        {type: 'string', value: 'END RES. - CIDADE'},
        {type: 'string', value: 'END RES. - BAIRRO'},
        {type: 'string', value: 'END RES. - LOGRADOURO'},
        {type: 'string', value: 'END RES. - COMPLEMENTO'},

        {type: 'string', value: 'ENDERECO DE CORRESPONDENCIA'},

        {type: 'string', value: 'END COM. - CEP'},
        {type: 'string', value: 'END COM. - ESTADO'},
        {type: 'string', value: 'END COM. - CIDADE'},
        {type: 'string', value: 'END COM. - BAIRRO'},
        {type: 'string', value: 'END COM. - LOGRADOURO'},
        {type: 'string', value: 'END COM. - COMPLEMENTO'},

        {type: 'string', value: 'EMAILS'},
        {type: 'string', value: 'TELEFONES'},
      ];

      const lines = this.items.map(i => {
        const sexo = i.dados_gerais.sexo === 1 ? 'MASCULINO' : (i.dados_gerais.sexo === 2 ? 'FEMININO' : 'DESCONHECIDO');
        const cnai = i.cnai.cnai_numero ? i.cnai.cnai_numero.toString() : '';
        const endcorrespondencia = i.contato.endereco_correspondencia === 1 ? 'RESIDENCIAL' : (i.contato.endereco_correspondencia === 2 ? 'COMERCIAL' : 'DESCONHECIDO');
        const recadastrado = i.recadastramentos.filter(z => z.data.substr(0, 4) === moment().format('YYYY')).length > 0;

        //ENDERECO RESIDENCIAL
        const endres_cep = i.contato.endereco_residencial ? i.contato.endereco_residencial.cep : '';
        const endres_estado = i.contato.endereco_residencial ? i.contato.endereco_residencial.estado : '';
        const endres_cidade = i.contato.endereco_residencial ? i.contato.endereco_residencial.cidade : '';
        const endres_bairro = i.contato.endereco_residencial ? i.contato.endereco_residencial.bairro : '';
        const endres_endereco = i.contato.endereco_residencial ? i.contato.endereco_residencial.endereco : '';
        const endres_complemento = i.contato.endereco_residencial ? i.contato.endereco_residencial.complemento : '';

        //ENDERECO COMERCIAL
        const endcom_cep = i.contato.endereco_comercial ? i.contato.endereco_comercial.cep : '';
        const endcom_estado = i.contato.endereco_comercial ? i.contato.endereco_comercial.estado : '';
        const endcom_cidade = i.contato.endereco_comercial ? i.contato.endereco_comercial.cidade : '';
        const endcom_bairro = i.contato.endereco_comercial ? i.contato.endereco_comercial.bairro : '';
        const endcom_endereco = i.contato.endereco_comercial ? i.contato.endereco_comercial.endereco : '';
        const endcom_complemento = i.contato.endereco_comercial ? i.contato.endereco_comercial.complemento : '';

        const emails = i.contato.emails.length ? i.contato.emails.reduce((carry, current) => {
          if (!current) return carry;
          return carry ? (carry + '; ' + current) : current;
        }, '') : '';

        let telefones = [...i.contato.telefones.residencial, ...i.contato.telefones.comercial, ...i.contato.telefones.celular];
        if (telefones.length) telefones = telefones.reduce((carry, current) => carry ? (carry + '; ' + current) : current, '');

        return [
          {type: 'string', value: i.dados_gerais.creci.toString()},
          {type: 'string', value: i.dados_gerais.cpf},
          {type: 'string', value: i.dados_gerais.nome},
          {type: 'string', value: i.dados_gerais.npa},
          {type: 'string', value: i.dados_gerais.data_inscricao},
          {type: 'string', value: i.dados_gerais.secundaria ? 'SIM' : 'NAO'},
          {type: 'string', value: i.transferencia.transferido ? 'SIM' : 'NAO'},
          {type: 'string', value: sexo},
          {type: 'string', value: cnai},
          {type: 'string', value: i.certidao.apto_certidao ? 'SIM' : 'NAO'},
          {type: 'string', value: i.certidao.validade},
          {type: 'string', value: i.info.recebeu_carteira ? 'SIM' : 'NAO'},
          {type: 'string', value: i.info.solenidade_entrega ? 'SIM' : 'NAO'},
          {type: 'string', value: i.foto ? 'SIM' : 'NAO'},
          {type: 'string', value: i.assinatura ? 'SIM' : 'NAO'},
          {type: 'string', value: recadastrado ? 'SIM' : 'NAO'},

          {type: 'string', value: i.rg.identidade},
          {type: 'string', value: i.rg.orgao_emissor},
          {type: 'string', value: i.dados_gerais.aniversario},
          {type: 'string', value: i.dados_gerais.mae},
          {type: 'string', value: i.dados_gerais.pai},

          {type: 'string', value: endres_cep},
          {type: 'string', value: endres_estado},
          {type: 'string', value: endres_cidade},
          {type: 'string', value: endres_bairro},
          {type: 'string', value: endres_endereco},
          {type: 'string', value: endres_complemento},

          {type: 'string', value: endcorrespondencia},

          {type: 'string', value: endcom_cep},
          {type: 'string', value: endcom_estado},
          {type: 'string', value: endcom_cidade},
          {type: 'string', value: endcom_bairro},
          {type: 'string', value: endcom_endereco},
          {type: 'string', value: endcom_complemento},

          {type: 'string', value: emails},
          {type: 'string', value: telefones},
        ];
      });

      zipcelx({
        filename: 'FICHAS_CADASTRAIS_' + moment().format('YYYY-MM-DD HH-mm-ss'),
        sheet: {
          data: [header, ...lines]
        },
      });
    },
    showLoadingDialog(text = false) {
      if (!text) {
        this.loadingDialog = false;
        this.loadingDialogMessage = '';
      } else {
        this.loadingDialog = true;
        this.loadingDialogMessage = typeof text === 'string' ? text : '';
      }
    },
    rulecreciInicialFinalPositivo(v) {
      const creciInicial = StringHelper.extractNumbers(this.creciInicial);
      const creciFinal = StringHelper.extractNumbers(v);
      if (!creciInicial || !creciFinal) return true;
      const ehPositivo = (parseInt(creciFinal) - parseInt(creciInicial)) > 0;
      if (!ehPositivo) return 'O valor final deve ser maior que o valor inicial';
      const ehAceitavel = (parseInt(creciFinal) - parseInt(creciInicial)) <= 100;
      if (!ehAceitavel) return 'O intervalo é muito grande, causará sobrecarga';
      return true;
    },
  },
  computed: {
    itemsBeautifyed() {
      return this.items.map(i => ({
        nome: i.dados_gerais.nome,
        creci: i.dados_gerais.creci,
        cpf: i.dados_gerais.cpf,
      }));
    },
  },
}
</script>
