<template>
  <v-container>
    <v-card>
      <v-card-title>Download de fotos</v-card-title>
      <v-card-subtitle>Baixe várias fotos de corretor em um único arquivo compactado</v-card-subtitle>
      <v-card-text>
        <v-form
          @submit.prevent="adicionarCorretor"
          :disabled="loadingFicha || loadingFotos"
          v-model="validForm"
        >
          <v-text-field
            label="CPF"
            placeholder="Digite o CPF e pressione ENTER"
            prepend-inner-icon="mdi-magnify"
            ref="inputcpf"
            :rules="[rules.cpfRule]"
            :loading="loadingFicha"
            :append-icon="iptCpf.trim() && validForm ? 'mdi-send' : undefined"
            @click:append="adicionarCorretor"
            dense
            outlined
            autofocus
            v-model="iptCpf"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-data-table
        :headers="tableHeaders"
        :items="tableItems"
        :items-per-page="50"
        :footer-props="{'items-per-page-options': [10, 20, 50, -1]}"
        dense
        no-data-text="Nenhum corretor adicionado na fila de download"
        no-results-text="Nenhum corretor encontrado"
      >
        <template v-slot:item.actions="{item}">
          <v-icon color="red" @click="removerCorretor(item.cpf)">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>
    <div class="mt-2 text-center">
      <v-btn
        small
        class="primary"
        :disabled="tableItems.length === 0"
        :loading="loadingFotos"
        @click="gerarArquivo"
      >
        <v-icon>mdi-download</v-icon> Download
      </v-btn>
    </div>
  </v-container>
</template>

<script>
  import I2brWebClient from '@/http/I2brWebClient';
  import ImageHelper from '@/helpers/ImageHelper';
  import FileSaver from 'file-saver';
  import JSZip from 'jszip';
  export default {
    name: 'Fotos',
    data: () => ({
      rules: {
        cpfRule: v => {
          if (!v.trim()) return true;
          if (!v.trim().replace(/[\d.-]/g,'')) return true;
          return 'CPF inválido';
        },
      },
      iptCpf: '',
      tableHeaders: [
        {text: 'CPF', value: 'cpf'},
        {text: 'CRECI', value: 'creci'},
        {text: 'NOME', value: 'nome'},
        {text: 'REMOVER', value: 'actions'},
      ],
      tableItems: [],
      webClient: new I2brWebClient(),
      loadingFicha: false,
      loadingFotos: false,
      validForm: null,
    }),
    methods: {
      async adicionarCorretor() {
        this.iptCpf = this.iptCpf.replace(/\D/g,'').trim();
        if (!this.iptCpf) return;
        if (this.iptCpf.length < 11 && this.iptCpf.length > 8) {
          for (let digitosRestantes = 11 - this.iptCpf.length; digitosRestantes > 0; digitosRestantes--) {
            this.iptCpf = '0' + this.iptCpf;
          }
        }
        if (this.tableItems.find(i => i.cpf === this.iptCpf)) {
          this.$alert({text: 'Esse corretor está na lista', type: 'info'});
          return;
        }
        this.loadingFicha = true;
        try {
          const ficha = await this.webClient.getFicha(this.iptCpf);
          if (ficha) {
            this.tableItems.push({
              cpf: ficha.dados_gerais.cpf,
              creci: ficha.dados_gerais.creci,
              nome: ficha.dados_gerais.nome,
            });
            this.iptCpf = '';
          }
          else this.$alert({text: 'Nenhum cadastro encontrado', type: 'error'});
        } finally {
          this.loadingFicha = false;
          await this.$nextTick(() => this.$refs['inputcpf'].tryAutofocus());
        }
      },
      removerCorretor(cpf) {
        this.tableItems = this.tableItems.filter(i => i.cpf !== cpf);
      },
      async gerarArquivo() {
        this.loadingFotos = true;
        try {
          const zip = new JSZip();
          for (const i of this.tableItems) {
            let fotoBlob = await this.webClient.getFoto(i.cpf);
            if (fotoBlob) {
              let base64str = await ImageHelper.blobToDataURI_async(fotoBlob);
              base64str = base64str.split(';base64,');
              let mime = base64str[0].replace('data:image/', '');
              let foto = base64str[1];
              zip.file(i.creci+"_foto." + mime, foto, {base64: true});
            }
          }
          const arquivo = await zip.generateAsync({type: 'blob'});
          FileSaver.saveAs(arquivo, 'fotos_corretores.zip');
        } catch (e) {
          this.$alert({text: 'Ocorreu um erro', type: 'error'});
        } finally {
          this.loadingFotos = false;
        }
      },
    },
  }
</script>

<style scoped>

</style>