<template>
  <async-container :loading="!loaded">
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      class="elevation-2"
      :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
    >
      <template v-slot:top>
        <v-card-title>Cidades com corretores ativos</v-card-title>
        <v-card-subtitle>Total de {{totalCidades}} cidades com {{totalCorretores}} corretores de imóveis</v-card-subtitle>
        <v-card-text>
          <v-text-field
            class="pt-0"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            label="Busque aqui..."
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </v-card-text>
      </template>
    </v-data-table>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import I2brWebClient from "@/http/I2brWebClient";
export default {
  name: 'CidadesComProfissionais',
  components: {AsyncContainer},
  data: () => ({
    loaded: false,
    items: [],
    headers: [
      {text: 'Cidade', value: 'cidade'},
      {text: 'Corretores ativos', value: 'colaboradores'},
    ],
    search: '',
  }),
  methods: {
    async loadData() {
      const webClient = new I2brWebClient();
      try {
        const r = await webClient.getCidadesComCorretoresAtivos();
        this.items = r.detalhes;
      } catch (e) {
        this.$router.push('/relatorios/conselhonet');
      } finally {
        this.loaded = true;
      }
    },
  },
  computed: {
    totalCidades() {
      return this.items.length;
    },
    totalCorretores() {
      return this.items.reduce((carry, item) => item.colaboradores + carry, 0);
    },
  },
  created() {
    this.loadData();
  },
}
</script>

<style scoped>

</style>