<template>
  <v-container>
    <p class="title mb-0">Relatórios do iCorretor</p>
    <p class="subtitle-1">Base de dados COFECI</p>
    <v-divider class="mb-5"/>
    <p class="body-1 text-center">No momento não há relatórios disponíveis</p>
  </v-container>
</template>

<script>
export default {
  name: 'Index'
}
</script>
