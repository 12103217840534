<template>
  <v-container>
    <p class="title mb-0">Email corretor (@creci.org)</p>
    <p class="subtitle-1">Serviço de redirecionamento do e-mail do <strong>CRECI-SP</strong></p>
    <v-divider class="mb-5"/>
    <v-sheet class="mb-3" outlined light rounded>
      <v-list-item @click="dialogAtualizarEmail = true">
        <v-list-item-content>
          <v-list-item-title>Atualizar email de redirecionamento</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
    <v-sheet class="mb-3" outlined light rounded>
      <v-list-item @click="dialogVerifica = true">
        <v-list-item-content>
          <v-list-item-title>Verificar email redirecionamento</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
    <!-- Forms -->
    <v-dialog width="400" max-width="94%" v-model="dialogAtualizarEmail">
      <v-card>
        <v-form ref="form-atualiza" @submit.prevent="atualizarEmail">
          <v-card-title>Atualizar email</v-card-title>
          <v-card-subtitle>Atualização do email de redirecionamento</v-card-subtitle>
          <v-card-text>
            <v-text-field
              label="CRECI"
              :rules="[rules.obrigatorio]"
              maxlength="7"
              v-model="iptCreci"
            ></v-text-field>
            <v-text-field
              label="CPF"
              :rules="[rules.obrigatorio]"
              maxlength="11"
              v-model="iptCpf"
            ></v-text-field>
            <v-text-field
              label="E-mail pessoal"
              hint="Endereço de e-mail pessoal para o redirecionamento"
              persistent-hint
              :rules="[rules.obrigatorio]"
              maxlength="128"
              v-model="iptEmail"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="primary" rounded outlined @click="dialogAtualizarEmail = false" :disabled="loading">Cancelar</v-btn>
            <v-btn color="primary" rounded type="submit" :loading="loading">Enviar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog width="400" max-width="94%" v-model="dialogVerifica">
      <v-card>
        <v-form ref="form-verifica" @submit.prevent="verificaEmail">
          <v-card-title>Verificar email</v-card-title>
          <v-card-text>
            <v-text-field
              label="E-mail de corretor"
              hint="Endereço de e-mail @creci.org"
              persistent-hint
              :rules="[rules.obrigatorio]"
              maxlength="128"
              v-model="iptEmail"
            ></v-text-field>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn color="primary" rounded outlined @click="dialogVerifica = false" :disabled="loading">Cancelar</v-btn>
            <v-btn color="primary" rounded type="submit" :loading="loading">Enviar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog width="500" max-width="94%" v-model="dialogResultado">
      <v-card>
        <v-card-title>
          Resposta do servidor
          <v-spacer/>
          <v-icon @click="dialogResultado = false">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <p style="white-space: pre-wrap">{{resultado}}</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: 'Index',
  data: () => ({
    dialogAtualizarEmail: false,
    dialogVerifica: false,
    dialogResultado: false,
    rules: {
      obrigatorio: v => !!v || 'Este campo é obrigatório',
    },
    iptCreci: '',
    iptCpf: '',
    iptEmail: '',
    loading: false,
    resultado: '',
  }),
  computed: {
    itemsSorted() {
      const i = this.items;
      return i.sort((a, b) => {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      });
    },
  },
  methods: {
    async atualizarEmail() {
      if (!this.$refs['form-atualiza'].validate()) return;
      const data = {
        'NrRegistro': this.iptCreci,
        'CPF': this.iptCpf,
        'Email': this.iptEmail,
      }
      this.loading = true;
      try {
        const response = await this.$http.post('/bridge/crecisp/AtualizaEmailRedirecionamento.php', data);
        this.resultado = response.data;
        this.dialogAtualizarEmail = false;
        this.dialogResultado = true;
      } finally {
        this.loading = false;
      }
    },
    async verificaEmail() {
      if (!this.$refs['form-verifica'].validate()) return;
      const data = {'Email': this.iptEmail};
      this.loading = true;
      try {
        const response = await this.$http.post('/bridge/crecisp/VerificaEmailRedirecionamento.php', data);
        this.resultado = response.data;
        this.dialogVerifica = false;
        this.dialogResultado = true;
      } finally {
        this.loading = false;
      }
    },
    limparCampos() {
      this.iptCreci = '';
      this.iptCpf = '';
      this.iptEmail = '';
    },
  },
}
</script>
