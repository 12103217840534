<template>
  <v-container>
    <p class="title mb-0">Relatórios</p>
    <p class="subtitle-1">Explore planilhas e estatísticas dos sistemas integrados ao SIS</p>
    <v-divider class="mb-5"/>
    <v-sheet class="mb-3" outlined light rounded>
      <v-list-item to="/relatorios/conselhonet">
        <v-list-item-icon>
          <v-img src="@/assets/brasao.svg" height="32" width="32" contain/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>ConselhoNET</v-list-item-title>
          <v-list-item-subtitle>Gere planilhas extraindo dados do ConselhoNET</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
    <v-sheet class="mb-3" outlined light rounded>
      <v-list-item to="/relatorios/icorretor">
        <v-list-item-icon>
          <v-img src="@/assets/logo.svg" height="32" width="32" contain/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>iCorretor</v-list-item-title>
          <v-list-item-subtitle>Consulte informações da base de dados do aplicativo</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
    <v-sheet class="mb-3" outlined light rounded>
      <v-list-item to="/relatorios/emailcorretor">
        <v-list-item-icon>
          <v-img src="@/assets/receive-mail.svg" height="32" width="32" contain/>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>E-mail corretor (@creci.org)</v-list-item-title>
          <v-list-item-subtitle>Serviço de redirecionamento de e-mail do <strong>CRECI-SP</strong></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-sheet>
  </v-container>
</template>

<script>
  export default {
    name: "Index"
  }
</script>
