<template>
  <v-container>
    <v-card>
      <v-card-title>Cadastros sem imagem</v-card-title>
      <v-card-subtitle>Corretores "ativos" sem foto ou assinatura</v-card-subtitle>

      <template v-if="loading">
        <v-progress-linear
          v-if="loading"
          buffer-value="0"
          color="success"
          stream
          :value="porcentagemCarregamento"
          class="mb-3"
        ></v-progress-linear>
        <v-card-text class="text-center">
          <p>{{loadingText}}</p>
          <v-btn color="error" @click="stop = true" :loading="stop">Cancelar</v-btn>
        </v-card-text>
      </template>
      <v-card-text v-else-if="contador === 0" class="text-center">
        <v-alert type="info">A varredura de imagem é um procedimento pesado, pode levar alguns minuto. Clique no botão abaixo para dar início. Você poderá interromper o carregamento para obter um resultado parcial.</v-alert>
        <v-btn color="primary" @click="loadData">Carregar</v-btn>
      </v-card-text>
      <v-card-text v-else class="text-center">
        <v-btn color="success" @click="loadData">Continuar</v-btn>
      </v-card-text>

      <v-divider></v-divider>
      <v-data-table
        :items="items"
        :headers="headers"
        :items-per-page="-1"
        hide-default-footer
        no-data-text="Nenhuma informação carregada"
      >
        <template v-slot:item.DataInscricao="{item}">{{dateFormat(item.DataInscricao)}}</template>
        <template v-slot:item.foto="{item}">
          <v-chip color="success" v-if="item.foto" small label outlined>SIM</v-chip>
          <v-chip color="error" v-else small label>NÃO</v-chip>
        </template>
        <template v-slot:item.assinatura="{item}">
          <v-chip color="success" v-if="item.assinatura" small label outlined>SIM</v-chip>
          <v-chip color="error" v-else small label>NÃO</v-chip>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
  import I2brWebClient from '@/http/I2brWebClient';
  import moment from 'moment';
  export default {
    name: 'SemFotoAss',
    data: () => ({
      items: [],
      headers: [
        {value: 'CRECI', text: 'CRECI'},
        {value: 'CPF_CNPJ', text: 'CPF'},
        {value: 'Nome', text: 'NOME'},
        {value: 'DataInscricao', text: 'Data de Inscrição'},
        {value: 'foto', text: 'Foto'},
        {value: 'assinatura', text: 'Assinatura'},
      ],
      stop: false,
      loading: false,
      qtdCadastros: null,
      contador: 0,
    }),
    methods: {
      async loadData() {
        this.stop = false;
        this.loading = true;
        try {
          const i2brWebClient = new I2brWebClient();
          this.qtdCadastros = await i2brWebClient.getCadastrosSemFotoAssQtd();
          while (this.qtdCadastros > this.contador && this.stop === false) {
            const items = await i2brWebClient.getCadastrosSemFotoAss(this.contador, 50);
            this.items = [...this.items, ...items];
            this.contador += 50;
          }
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
        this.stop = false;
      },
      dateFormat(date) {
        return moment(date).format('DD/MM/YYYY');
      },
    },
    computed: {
      loadingText() {
        return this.qtdCadastros ? 'Carregando... ' + this.contador + ' de ' + this.qtdCadastros + ' cadastros ativos' : 'Carregando itens...';
      },
      porcentagemCarregamento() {
        return this.qtdCadastros ? ((this.contador / this.qtdCadastros) * 100).toFixed() : 0;
      },
    },
  }
</script>
